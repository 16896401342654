import { styled } from '@mui/material/styles';
import { Typography, Button } from '@mui/material';

export const AppContainer = styled(({ isAuthenticated, ...props }) => (
  <div {...props} />
))(({ isAuthenticated, theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.grey['100'],
  minHeight: '100vh',
  ...(isAuthenticated && {
    marginLeft: theme.margin.xxl,
  }),
}));

export const AppContent = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

export const EmailResendWrapper = styled('div')(({ theme }) => ({
  height: 64,
  width: '100%',
  backgroundColor: theme.palette.grey['900'],
  color: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.transparent.main}`,
}));

export const UserEmail = styled(Typography)(({ theme }) => ({
  marginLeft: theme.margin.xs,
  marginRight: theme.margin.md,
}));

export const ResendButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.common.white} !important`,
}));
