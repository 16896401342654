/* eslint-disable jsx-a11y/anchor-has-content */
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

export const Wrapper = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  left: 0,
  bottom: 0,
  width: 64,
  height: '100vh',
  backgroundColor: theme.palette.common.black,
  zIndex: 1201,
  float: 'left',
}));

export const OrganizationItem = styled(({ empty, ...props }) => (
  <Avatar {...props} />
))(({ empty, theme }) => ({
  borderRadius: 10,
  background: empty && theme.palette.common.white,
  color: empty && theme.palette.common.black,
}));

export const LinkItem = styled(({ active, reloadDocument, ...props }) =>
  props.to ? (
    <Link reloadDocument={reloadDocument} to="/" {...props} />
  ) : (
    <a {...props} />
  )
)(({ active, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: 64,
  height: 64,
  minHeight: 64,
  ...theme.typography.overline,
  color: active ? theme.palette.common.white : theme.palette.grey['700'],
  textDecoration: 'none',
  textTransform: 'none',
  cursor: 'pointer',

  '& svg': {
    marginBottom: 2,
  },
}));

export const LogoContainer = styled(LinkItem)({
  borderBottom: '1px solid rgba(255, 255, 255, 0.32)',
});
