const labels = {
  CANNOT_USE_INCLUDE_AND_EXCLUDE_AT_THE_SAME_TIME:
    'Cannot use include and exclude at the same time',
  UNEXPECTED_ERROR: 'Unexpected error, please try again later',
  CANDIDATE_NOT_FOUND: 'Contact not found',
  CANDIDATE_NOT_IMPORTED: 'Contact not imported',
  COMMENT_NOT_FOUND: 'Comment not found',
  EMAIL_ALREADY_TAKEN: 'The user already exists',
  EMAIL_NOT_FOUND: 'Email not found',
  INVALID_ARGUMENTS: 'Invalid arguments',
  INVALID_PASSWORD: 'Invalid credentials',
  INVALID_RESET_TOKEN: 'Invalid token',
  INVITATION_ALREADY_EXISTS: 'Invitation already exists',
  INVITATION_NOT_FOUND: 'Invitation not found',
  MEMBER_ALREADY_EXISTS: 'Member already exists',
  PROJECT_NOT_FOUND: 'Project not found',
};

export default labels;
