import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Typography } from '@mui/material';

export const StepListItem = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.grey['900'],

  '& a': {
    ...theme.typography.title,
    color: theme.palette.info.main,
    textDecoration: 'none',
  },
}));

export const NumberWrapper = styled(Typography)(({ theme }) => ({
  width: 24,
  height: 24,
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const FAQContainer = styled(Accordion)({
  boxShadow: 'none',

  '&::before': {
    display: 'none',
  },
});

export const FAQTitle = styled(AccordionSummary)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.info.main,
  padding: 0,
}));

export const FAQDescription = styled(AccordionDetails)(({ theme }) => ({
  ...theme.typography.body2,
  padding: `${theme.padding.sm} 0`,
}));

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.margin.md,
}));

export const Grid = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: `${theme.margin.lg} auto`,
  gap: `${theme.margin.md} ${theme.margin.sm}`,
  marginBottom: theme.margin.xl,
  marginTop: theme.margin.sm,
}));
