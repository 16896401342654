import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router';
import { useParams, useMatch, useLocation } from 'react-router-dom';
import {
  Settings,
  FolderSpecial,
  ContactPage,
  CheckCircle,
  Add,
  AdminPanelSettings,
} from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Wrapper, OrganizationItem, LinkItem, LogoContainer } from './styled';
import useOrganizations from '../../Hooks/useOrganizations';
import useOrganization from '../../Hooks/useOrganization';
import useUserSession from '../../Hooks/useUserSession';
import LogoSrc from '../../assets/img/small-logo.png';
import { LAST_ORG_ID_KEY } from 'Utils/Storage';

const SideBar = ({ safeMode }) => {
  const { orgId: orgIdFromParams } = useParams();
  const { data: session } = useUserSession();
  const {
    data: organizations = [],
    loading: areOrganizatonsLoading,
    isFetched,
  } = useOrganizations(!safeMode);
  const navigate = useNavigate();
  const location = useLocation();
  const [orgAnchorEl, setOrgAnchorEl] = useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);
  const isVerifyRoute = useMatch('/auth/verify-email/*');
  const isAuthRoute = useMatch('/auth/*');

  const storageId = localStorage.getItem(LAST_ORG_ID_KEY);
  const orgId = orgIdFromParams || storageId;
  const foundOrganization = useOrganization(orgId);

  useEffect(() => {
    if (foundOrganization) {
      localStorage.setItem(LAST_ORG_ID_KEY, orgId);
    } else if (isFetched && storageId) {
      navigate(`/organization/${storageId}/projects`);
    }
  }, [foundOrganization, isFetched]);

  const { name } = organizations.find((org) => String(org.id) === orgId) || {};

  const handleOrgClick = (event) => {
    setOrgAnchorEl(event.currentTarget);
  };
  const handleAdminClick = (event) => {
    setAdminAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOrgAnchorEl(null);
    setAdminAnchorEl(null);
  };

  const changeOrg = (id) => {
    handleClose();
    navigate(`/organization/${id}/projects`);
  };

  const handleCreate = () => {
    handleClose();
    navigate(`/organization/new/create`);
  };

  // const handleJoin = () => {
  //   handleClose();
  //   navigate(`/organization/${orgId}/join`);
  // };

  const handleSettingsNavigate = () => {
    handleClose();
    navigate(`/organization/${orgId}`);
  };

  if (isAuthRoute && !isVerifyRoute) return null;

  return (
    <Wrapper>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <LogoContainer href="/">
          <img src={LogoSrc} alt="logo" width="32" height="32" />
        </LogoContainer>
        <Box sx={{ marginBottom: 'auto' }}>
          {!areOrganizatonsLoading && (
            <LinkItem onClick={handleOrgClick} reloadDocument={safeMode}>
              <OrganizationItem
                empty={!name}
                alt="Organization Name"
                src="add_real_src"
              >
                {name ? undefined : <Add />}
              </OrganizationItem>
            </LinkItem>
          )}
          <Menu
            anchorEl={orgAnchorEl}
            open={!!orgAnchorEl}
            onClose={handleClose}
            PaperProps={{
              style: {
                overflowY: 'hidden',
              },
            }}
          >
            <MenuList
              autoFocus
              sx={{
                maxHeight: 36 * 6, // item height + max elements to display
                overflowY: 'auto',
                padding: 0,

                '&:focus': {
                  boxShadow: 'none',
                  outline: 'none',
                },
              }}
            >
              {organizations.map((org) => (
                <MenuItem
                  key={org.id}
                  onClick={() => changeOrg(org.id)}
                  disableRipple
                >
                  <ListItemText>{org.name}</ListItemText>
                  <ListItemIcon>
                    {orgId === String(org.id) && (
                      <CheckCircle color="success" fontSize="small" />
                    )}
                  </ListItemIcon>
                </MenuItem>
              ))}
            </MenuList>
            <Box>
              {organizations.length > 0 && <Divider sx={{ my: 0.5 }} />}
              {/* <MenuItem onClick={handleJoin} disableRipple>
                Join Organization
              </MenuItem> */}
              <MenuItem onClick={handleCreate} disableRipple>
                Create Organization
              </MenuItem>
              {orgId && (
                <MenuItem onClick={handleSettingsNavigate} disableRipple>
                  Organization Settings
                </MenuItem>
              )}
            </Box>
          </Menu>
          {orgId && (
            <LinkItem
              reloadDocument={safeMode}
              to={`/organization/${orgId}/projects`}
              active={location.pathname.match(
                /^\/organization\/[0-9]+\/project/g
              )}
            >
              <FolderSpecial />
              Projects
            </LinkItem>
          )}
          <LinkItem
            to="/talent-pool"
            reloadDocument={safeMode}
            active={location.pathname === '/talent-pool'}
          >
            <ContactPage />
            Talents
          </LinkItem>
        </Box>
        {session?.isAdmin && (
          <>
            <LinkItem
              onClick={handleAdminClick}
              reloadDocument={safeMode}
              active={location.pathname.includes('/admin')}
            >
              <AdminPanelSettings />
              Admin
            </LinkItem>
            <Menu
              anchorEl={adminAnchorEl}
              open={!!adminAnchorEl}
              onClose={handleClose}
              PaperProps={{
                style: {
                  overflowY: 'hidden',
                },
              }}
            >
              <MenuList
                sx={{
                  overflowY: 'auto',
                  padding: 0,

                  '&:focus': {
                    boxShadow: 'none',
                    outline: 'none',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('admin/orders');
                  }}
                  disableRipple
                >
                  <ListItemText>Orders</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('admin/organizations');
                  }}
                  disableRipple
                >
                  <ListItemText>Organizations</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('admin/users');
                  }}
                  disableRipple
                >
                  <ListItemText>Users</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('admin/projects');
                  }}
                  disableRipple
                >
                  <ListItemText>Projects</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                    navigate('admin/recruiters-performance');
                  }}
                  disableRipple
                >
                  <ListItemText>Recruiter's performance</ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        )}
        <LinkItem
          to="/account"
          reloadDocument={safeMode}
          active={location.pathname === '/account'}
        >
          <Settings />
          Settings
        </LinkItem>
      </Box>
    </Wrapper>
  );
};

export default SideBar;
