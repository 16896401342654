import { useEffect, useMemo, useState } from 'react';
import {
  Outlet,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
  useMatch,
  matchPath,
} from 'react-router-dom';
import { Typography } from '@mui/material';
import { APP_EVENTS, useAppEvents } from 'Hooks/useAppEvents';
import ProjectGate from 'Pages/Projects/Components/ProjectGate';
import LinkedInInstructionsModal from 'Pages/Organization/Components/LinkedInInstructionsModal';
import lazyLoad from './Utils/lazyLoad';
import Storage, { LAST_ORG_ID_KEY } from './Utils/Storage';
import ErrorBoundary from './Components/ErrorBoundary';
import useUserSession from './Hooks/useUserSession';
import * as Org from './Pages/Organization';
import { usePageTracking } from './Hooks/useAnalytics';
import usePrimaryEmail from './Hooks/usePrimaryEmail';
import useResendEmailConfirmation from './Hooks/Mutations/useResendEmailConfirmation';
import {
  AppContent,
  AppContainer,
  ResendButton,
  EmailResendWrapper,
  UserEmail,
} from './styled';

const Drawer = lazyLoad(() => import('./Components/Drawer'));
const SideBar = lazyLoad(() => import('./Components/SideBar'));
const Accept = lazyLoad(() => import('./Pages/Organization/Components/Accept'));
const OrganizationSummary = lazyLoad(() =>
  import('./Pages/Organization/Components/Summary')
);
const Account = lazyLoad(() => import('./Pages/Account'));
const Auth = lazyLoad(() => import('./Pages/Auth/Components/Routes'));
const Campaign = lazyLoad(() => import('./Pages/Campaign'));
const Candidates = lazyLoad(() => import('./Pages/Candidates'));
const Messaging = lazyLoad(() => import('./Pages/Messaging'));
const Profile = lazyLoad(() => import('./Pages/Profile'));
const Projects = lazyLoad(() => import('./Pages/Projects'));
const ProjectOverview = lazyLoad(() =>
  import('./Pages/Projects/Components/Overview')
);
const Interviews = lazyLoad(() => import('./Pages/Interviews'));
const Password = lazyLoad(() => import('./Pages/Auth/Components/Password'));
const TalentPool = lazyLoad(() => import('./Pages/TalentPool'));
const Verify = lazyLoad(() => import('./Pages/Auth/Components/Verify'));
const UserAdministration = lazyLoad(() =>
  import('./Pages/Admin/Components/Users')
);
const ProjectAdministration = lazyLoad(() =>
  import('./Pages/Admin/Components/Projects')
);
const RecruitersPerformance = lazyLoad(() =>
  import('./Pages/Admin/Components/Performance')
);
const OrganizationAdministration = lazyLoad(() =>
  import('./Pages/Admin/Components/Organizations')
);
const OrderAdministration = lazyLoad(() =>
  import('./Pages/Admin/Components/Orders')
);
const Services = lazyLoad(() => import('./Pages/Ordering/Components/Services'));
const Subscription = lazyLoad(() =>
  import('./Pages/Ordering/Components/Subscription')
);

const Wrapper = ({ isAuthenticated }) => (
  <>
    {isAuthenticated && <SideBar />}
    <AppContainer isAuthenticated={isAuthenticated}>
      {isAuthenticated && <Drawer />}
      <AppContent>
        <Outlet />
      </AppContent>
    </AppContainer>
  </>
);

const privateRoutes = (lastOrgId, isAdmin, redirect) => {
  return (
    <Routes>
      <Route
        exact
        path="/organization/:orgId/join/:token"
        element={<Accept />}
      />
      <Route element={<Wrapper isAuthenticated />}>
        <Route exact path="account" element={<Account />} />
        <Route exact path="account/password" element={<Password />} />
        <Route exact path="auth/verify-email/:token" element={<Verify />} />
        <Route path="organization">
          <Route index element={<Org.Start />} />
          <Route path="new/*" element={<Org.Empty />} />
          <Route path=":orgId">
            <Route index element={<Org.View root />} />
            <Route path="project/:projectId" element={<ProjectGate />}>
              <Route path="campaign">
                <Route index element={<Campaign />} />
                <Route path=":tab" element={<Campaign />} />
              </Route>
              <Route path="candidates">
                <Route index element={<Candidates />} />
                <Route path=":tabName" element={<Candidates />} />
              </Route>
              <Route path="messaging">
                <Route index element={<Messaging />} />
                <Route path=":bucket">
                  <Route index element={<Messaging />} />
                  <Route path=":conversationId" element={<Messaging />} />
                </Route>
              </Route>
              <Route exact path="overview" element={<ProjectOverview />} />
              <Route
                exact
                path="overview/:action"
                element={<ProjectOverview />}
              />
              <Route exact path="interviews" element={<Interviews />} />
            </Route>
            <Route exact path="projects" element={<Projects />} />
            <Route exact path="summary" element={<OrganizationSummary />} />
          </Route>
          <Route path=":orgId/*" element={<Org.View />} />
        </Route>
        <Route path="profile" element={<Profile />} />
        <Route exact path="talent-pool" element={<TalentPool />} />
        <Route path="ordering">
          <Route exact path="services" element={<Services />} />
          <Route exact path="subscription" element={<Subscription />} />
        </Route>
        {isAdmin && (
          <Route path="admin">
            <Route exact path="users" element={<UserAdministration />} />
            <Route exact path="projects" element={<ProjectAdministration />} />
            <Route
              exact
              path="recruiters-performance"
              element={<RecruitersPerformance />}
            />
            <Route
              exact
              path="organizations"
              element={<OrganizationAdministration />}
            />
            <Route exact path="orders" element={<OrderAdministration />} />
            <Route index path="*" element={<Navigate to="users" />} />
          </Route>
        )}
        <Route
          index
          path="*"
          element={
            <Navigate
              replace
              to={
                redirect
                  ? 'profile'
                  : lastOrgId
                  ? `organization/${lastOrgId}/projects`
                  : 'organization'
              }
            />
          }
        />
      </Route>
    </Routes>
  );
};

const publicRoutes = (
  <Routes>
    <Route element={<Wrapper />}>
      <Route path="*" element={<Auth />} />
    </Route>
  </Routes>
);

const storage = new Storage();
const path = storage.getItem('path');
storage.removeItem('path');
const lastOrgId = storage.getItem(LAST_ORG_ID_KEY);
const isPathToRecoverMatches = path
  ? matchPath('/organization/:orgId/join/:token', path)
  : false;

const App = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matchesOrgJoining = useMatch('/organization/:orgId/join/:token');
  const matchesEmailVerification = useMatch('auth/verify-email/:token');
  const { mutateAsync: resendEmailConfirmation, isLoading: isResendLoading } =
    useResendEmailConfirmation();
  const { data: session, error, isLoading } = useUserSession();
  const currentUserEmail = usePrimaryEmail();
  const [isEmailResent, setIsEmailResent] = useState();
  const [isConnectModalOpen, setIsConnectModalOpen] = useState();
  const appEvents = useAppEvents();
  const shouldRedirectToProfile =
    !!session?.requiredSteps.PROFILE_COMPLETION_REQUIRED &&
    !isPathToRecoverMatches;

  const isAuthenticated = useMemo(
    () => !isLoading && !error && !!session,
    [error, isLoading, session]
  );

  const routes = useMemo(
    () =>
      isAuthenticated
        ? privateRoutes(lastOrgId, !!session?.isAdmin, shouldRedirectToProfile)
        : publicRoutes,
    [isAuthenticated, lastOrgId, session, shouldRedirectToProfile]
  );

  usePageTracking();

  useEffect(() =>
    // unsubscribe returned
    appEvents.subscribe(APP_EVENTS.OPEN_LI_INSTRUCTIONS, () => {
      setIsConnectModalOpen(true);
    })
  );

  useEffect(() => {
    if (!isAuthenticated || matchesOrgJoining || matchesEmailVerification)
      return;
    if (path && pathname !== path) {
      navigate(path, { replace: true });
      return;
    }
  }, [isAuthenticated, matchesOrgJoining, matchesEmailVerification]);

  if (isLoading) {
    return null;
  }

  return (
    <ErrorBoundary>
      {session?.requiredSteps.EMAIL_VERIFICATION_REQUIRED && (
        <EmailResendWrapper>
          <Typography variant="body2">
            We have sent you an email confirmation link to
          </Typography>
          <UserEmail variant="subtitle">{currentUserEmail}</UserEmail>
          {isEmailResent ? (
            <Typography variant="body2">Email resent!</Typography>
          ) : (
            <ResendButton
              loading={isResendLoading}
              color="ternary"
              onClick={async () => {
                await resendEmailConfirmation();
                setIsEmailResent(true);
              }}
            >
              Resend
            </ResendButton>
          )}
        </EmailResendWrapper>
      )}
      {routes}

      {isConnectModalOpen && (
        <LinkedInInstructionsModal
          open
          onClose={() => setIsConnectModalOpen(false)}
        />
      )}
    </ErrorBoundary>
  );
};

App.propTypes = {};

export default App;
