import * as yup from 'yup';

export const URL_PATTERN =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

yup.addMethod(yup.string, 'validUrl', function () {
  return this.test(
    'Valid Url',
    'Please enter a valid URL',
    function (value = '') {
      if (!value?.length) return true;
      return !!value.trim().match(URL_PATTERN);
    }
  );
});

yup.addMethod(yup.string, 'trim', function () {
  return this.test(
    'Trim before validation',
    'This field is required',
    function (value = '') {
      return !!value.trim();
    }
  );
});

yup.addMethod(yup.string, 'linkedInUrl', function () {
  return this.test(
    'LinkedIn Url',
    'Wrong format for a contact example. It should start with https://www.linkedin.com/',
    function (value = '') {
      if (!value?.length) return true;
      const formattedValue = value.trim();

      return (
        !!formattedValue.match(URL_PATTERN) &&
        (formattedValue.startsWith('https://www.linkedin.com/') ||
          formattedValue.startsWith('https://linkedin.com/'))
      );
    }
  );
});
