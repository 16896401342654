import { createContext } from 'react';
import AccountApi from './AccountApi';
import AdminApi from './AdminApi';
import AuthApi from './AuthApi';
import InterviewApi from './InterviewApi';
import MessageApi from './MessageApi';
import OrgApi from './OrgApi';
import ProjectApi from './ProjectApi';
import Storage from '../Storage';
import PaymentApi from './PaymentApi';

const storage = new Storage();

const config = {
  headers: () => {
    const accessToken = storage.getItem('accessToken');
    return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
  },
};

export const apis = {
  account: new AccountApi(config),
  admin: new AdminApi(config),
  auth: new AuthApi(config),
  interview: new InterviewApi(config),
  message: new MessageApi(config),
  org: new OrgApi(config),
  project: new ProjectApi(config),
  payment: new PaymentApi(config),
};

export default createContext(apis);
