import { useQuery } from 'react-query';
import { useMatch } from 'react-router-dom';
import useOrgApi from '../Utils/Api/useOrgApi';

export default function useOrganizations(enabled = true) {
  const isAuthRoute = useMatch('/auth/*');
  const api = useOrgApi();

  return useQuery(
    'organizations',
    () => {
      return api.getOrganizations();
    },
    {
      enabled: enabled && !isAuthRoute,
    }
  );
}
