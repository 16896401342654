import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag?.('event', 'page_view', {
      page_location: window.location.href,
    });
  }, [location]);
};
