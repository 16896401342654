import useProjects from 'Hooks/useProjects';
import { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ToastService } from 'Utils/ToastService';

const ProjectGate = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { data: projects, isFetched } = useProjects();

  useEffect(() => {
    if (
      isFetched &&
      projects &&
      !projects.find((project) => project.id.toString() === projectId)
    ) {
      ToastService.error({
        content: 'Project does not exist for the organization',
      });
      navigate('/', { replace: true });
    }
  }, [projects]);

  if (!projects || projects.length === 0) return null;

  return <Outlet />;
};

export default ProjectGate;
