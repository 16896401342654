import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';
import SideBar from '../SideBar';

function FallbackComponent({ error, orgId }) {
  return (
    <>
      {!orgId && <SideBar safeMode />}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: 'calc(100vh - 76px)',
        }}
      >
        <h2>Oops, something went wrong. Please try again.</h2>
        <Button onClick={() => window.location.reload()}>Refresh</Button>
      </Box>
    </>
  );
}

function ErrorBoundary({ children, orgId }) {
  return (
    <Sentry.ErrorBoundary fallback={() => <FallbackComponent orgId={orgId} />}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  orgId: PropTypes.string,
};

export default ErrorBoundary;
