import { useQuery } from 'react-query';
import useAccountApi from '../Utils/Api/useAccountApi';

export default function useUserEmails() {
  const api = useAccountApi();

  const {
    isLoading: loading,
    data = [],
    error,
  } = useQuery('emails', async () => {
    const data = await api.getEmails();
    return data?.emails;
  });

  return {
    data,
    error,
    loading,
  };
}
