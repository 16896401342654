import Api from './Api';

export default class AccountApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  getEmails() {
    return this.doGet({ path: '/account/emails' });
  }

  getProfile() {
    return this.doGet({ path: '/account/profile' });
  }

  updateProfile(profile) {
    return this.doPost({ path: '/account/profile' }, profile);
  }

  submitVerifyEmail(token) {
    return this.doPost({ path: `/account/verify-email/${token}` });
  }

  disconnectLinkedIn() {
    return this.doDelete({ path: '/linkedin-gateway/connected-account' });
  }

  getActivitySettings() {
    return this.doGet({ path: '/linkedin-gateway/connected-account/settings' });
  }

  updateActivitySettings(settings) {
    return this.doPost(
      {
        path: '/linkedin-gateway/connected-account/settings',
      },
      settings
    );
  }
}
