import MuiModal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Close as CloseIcon } from '@mui/icons-material';
import { Title, CloseButton, Subtitle } from './styled';

export default function Modal({
  children,
  open,
  title,
  width = 528,
  subTitle,
  onClose,
  className,
  closable = true,
}) {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      {...(className && {
        classes: { root: className },
      })}
      sx={{ zIndex: 1250 }}
    >
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          '&:focus': {
            outline: 'none',
          },
          maxHeight: '90vh',
        }}
      >
        {onClose && closable && (
          <CloseButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </CloseButton>
        )}
        <Box
          sx={{
            p: '64px',
            width,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {title && <Title variant="h4">{title}</Title>}
          {subTitle && <Subtitle variant="body1">{subTitle}</Subtitle>}
          {children}
        </Box>
      </Paper>
    </MuiModal>
  );
}
