import { Route, Routes } from 'react-router-dom';
import lazyLoad from '../../../../Utils/lazyLoad';

const Join = lazyLoad(() => import('../Join'));
const Create = lazyLoad(() => import('../Create'));
const Empty = lazyLoad(() => import('../Empty'));

export default () => {
  return (
    <Routes>
      <Route element={<Empty />}>
        <Route path="join" element={<Join />} />
        <Route path="create" element={<Create />} />
      </Route>
    </Routes>
  );
};
