import Api from './Api';

export default class PaymentApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  getSettings() {
    return this.doGet({ path: '/payment/settings' });
  }

  calculatePrice(candidates, services, country) {
    return this.doPost(
      { path: '/payment/price' },
      {
        candidates,
        services,
        country,
      }
    );
  }

  createCardOrder(params, options = {}) {
    return this.doPost({ path: '/payment/card/create', ...options }, params);
  }

  createBankOrder(params, options = {}) {
    return this.doPost({ path: '/payment/bank/create', ...options }, params);
  }
}
