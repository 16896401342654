import { styled } from '@mui/material/styles';
import { IconButton, Typography } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  marginBottom: theme.margin.md,
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['900'],
  marginBottom: theme.margin.xl,
}));

export const CloseButton = styled(IconButton)({
  position: 'absolute !important',
  top: 16,
  right: 16,
});
