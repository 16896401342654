import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      useErrorBoundary: false, // for improving UX, don't show Error Boundary on GET requests
    },
  },
});
