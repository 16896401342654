import Api from './Api';

export const LAST_EMAIL_KEY = 'last-email';

export default class AuthApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  getSession() {
    return this.doGet({ path: '/session' });
  }

  login(email, password) {
    return this.doPost(
      {
        path: '/auth/login',
      },
      { email, password }
    );
  }

  register(email, password, invitationToken) {
    return this.doPost(
      {
        path: '/auth/register',
      },
      { email, password, invitationToken }
    );
  }

  resetPassword(email) {
    return this.doPost({ path: '/auth/reset-password' }, { email });
  }

  submitResetPassword(token, password) {
    return this.doPost({ path: `/auth/reset-password/${token}` }, { password });
  }

  changePassword(password) {
    return this.doPost({ path: `/account/change-password` }, { password });
  }

  logout() {
    return this.doGet({
      path: '/auth/logout',
    });
  }

  resendEmailConfirmation() {
    return this.doPost({ path: '/account/resend-email-verification' });
  }
}
