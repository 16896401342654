import Api from './Api';

export default class AdminApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  getConnectedAccounts(params = {}) {
    return this.doGet({ path: '/admin/connected-accounts', params });
  }

  getOrders(params = {}) {
    return this.doGet({ path: '/admin/orders', params });
  }

  getOrganizations(params = {}) {
    return this.doGet({ path: '/admin/organisations', params });
  }

  getProjects(params = {}) {
    return this.doGet({ path: '/admin/projects', params });
  }

  getUsers(params = {}) {
    return this.doGet({ path: '/admin/users', params });
  }

  terminateUser(userId) {
    return this.doPost({ path: `/admin/users/${userId}/terminate` });
  }

  assignToOrganizations(userId, orgIds) {
    return this.doPost(
      { path: '/admin/users/assign' },
      {
        userId,
        orgIds,
      }
    );
  }

  setVerifiedUserEmail(email) {
    return this.doPost(
      { path: '/admin/users/verify' },
      {
        email,
      }
    );
  }

  cancelOrder(orderId) {
    return this.doPost({ path: `/admin/orders/manual/cancel` }, { orderId });
  }

  succeedOrder(orderId) {
    return this.doPost({ path: `/admin/orders/manual/succeed` }, { orderId });
  }

  notPaidOrder(orderId) {
    return this.doPost({ path: `/admin/orders/manual/not-paid` }, { orderId });
  }
}
