export const LAST_ORG_ID_KEY = 'last-org-id';
export default class Storage {
  constructor(store = localStorage, config = {}) {
    this.config = config;
    this.store = store;
  }

  makeKey(key) {
    return []
      .concat(this.config.prefix ?? [])
      .concat(key ?? [])
      .join('-');
  }

  serializeItem(item) {
    if (this.config.format === 'json') {
      return JSON.stringify(item);
    } else {
      return item;
    }
  }

  deserializeItem(value) {
    if (this.config.format === 'json') {
      try {
        return JSON.parse(value);
      } catch (err) {
        console.error('JSON_PARSE_ERROR', err);
        return null;
      }
    } else {
      return value;
    }
  }

  setItem(key, item) {
    this.store.setItem(this.makeKey(key), this.serializeItem(item));
  }

  getItem(key, defaultValue = null) {
    const item = this.store.getItem(this.makeKey(key));
    return item ? this.deserializeItem(item) : defaultValue;
  }

  removeItem(key) {
    this.store.removeItem(this.makeKey(key));
  }
}
