import Api from './Api';

export default class OrgApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  async getOrganizations() {
    const data = await this.doGet({ path: '/organisations' });
    return data?.organisations || [];
  }

  async getOrganization(orgId) {
    const data = await this.doGet({
      path: `/organisations/${orgId}`,
    });
    return data?.organisation;
  }

  createOrganization(name, domainName) {
    return this.doPost({ path: '/organisations' }, { name, domainName });
  }

  removeOrganization(orgId) {
    return this.doDelete({ path: `/organisations/${orgId}` });
  }

  async updateOrganization(orgId, name, domainName) {
    const { organisation } = await this.doPost(
      { path: `/organisations/${orgId}` },
      { name, domainName }
    );
    return organisation;
  }

  joinOrganization(token) {
    return this.doPost({ path: `/organisations/join/${token}` });
  }

  getDomains(orgId) {
    return this.doGet({ path: `/organisations/${orgId}/domains` });
  }

  createDomain(orgId, domain) {
    return this.doPost({ path: `/organisations/${orgId}/domains` }, { domain });
  }

  removeDomain(orgId, domainId) {
    return this.doDelete({
      path: `/organisations/${orgId}/domains/${domainId}`,
    });
  }

  async getMembers(orgId) {
    const data = await this.doGet({
      path: `/organisations/${orgId}/members`,
    });
    return data;
  }

  removeMember(orgId, memberId) {
    return this.doDelete({
      path: `/organisations/${orgId}/members/${memberId}`,
    });
  }

  changeMemberRole(orgId, memberId, role) {
    return this.doPost(
      {
        path: `/organisations/${orgId}/members/${memberId}/change-role`,
      },
      { role }
    );
  }

  async getInvitations(orgId) {
    const data = await this.doGet({
      path: `/organisations/${orgId}/invitations`,
    });
    return data?.invitations;
  }

  createInvitation(orgId, email, role) {
    return this.doPost(
      { path: `/organisations/${orgId}/invitations` },
      { email, role }
    );
  }

  removeInvitation(orgId, invitationId) {
    return this.doDelete({
      path: `/organisations/${orgId}/invitations/${invitationId}`,
    });
  }

  getConnectedAccounts(orgId) {
    return this.doGet({
      path: `/organisations/${orgId}/connected-accounts`,
    });
  }

  setConnectedAccount(projectId, connectedAccountId) {
    return this.doPost(
      {
        path: `/projects/${projectId}/set-connected-account`,
      },
      { connectedAccountId }
    );
  }

  createOrganizationProject(orgId, project) {
    return this.doPost(
      {
        path: `/organisations/${orgId}/projects`,
      },
      project
    );
  }

  getOrganizationSummary(orgId) {
    return this.doGet({
      path: `/organisations/${orgId}/summary`,
    });
  }
}
