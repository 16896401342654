import Api from './Api';

export default class InterviewApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  markInterview(projectId, candidateId, status) {
    return this.doPost({
      path: `/projects/${projectId}/interviews/${candidateId}/mark-as/${status}`,
    });
  }

  markAsNotInterested(projectId, candidateId) {
    return this.markInterview(projectId, candidateId, 'not-interested');
  }

  markAsInterview(projectId, candidateId) {
    return this.markInterview(projectId, candidateId, 'interview');
  }

  markAsEngaged(projectId, candidateId) {
    return this.markInterview(projectId, candidateId, 'engaged');
  }

  getProjectInterviews(projectId) {
    return this.doGet({ path: `/projects/${projectId}/interviews` });
  }

  moveInterviewIntoStage(projectId, candidateId, stageId) {
    return this.doPost(
      {
        path: `/projects/${projectId}/interviews/${candidateId}/move-into-stage`,
      },
      { stageId }
    );
  }

  getComments(projectId, candidateId) {
    return this.doGet({
      path: `/projects/${projectId}/candidates/${candidateId}/comments`,
    });
  }

  sendComment(projectId, candidateId, comment) {
    return this.doPost(
      {
        path: `/projects/${projectId}/candidates/${candidateId}/comments`,
      },
      {
        comment,
      }
    );
  }

  removeComment(projectId, candidateId, commentId) {
    return this.doDelete({
      path: `/projects/${projectId}/candidates/${candidateId}/comments/${commentId}`,
    });
  }

  setInterviewRating(projectId, candidateId, rating) {
    return this.doPost(
      {
        path: `/projects/${projectId}/interviews/${candidateId}/rating`,
      },
      { rating }
    );
  }

  deleteInterviewRating(projectId, candidateId) {
    return this.doDelete({
      path: `/projects/${projectId}/interviews/${candidateId}/rating`,
    });
  }

  setInterviewSchedule(projectId, candidateId, actionScheduledAt) {
    return this.doPost(
      {
        path: `/projects/${projectId}/interviews/${candidateId}/scheduled-action`,
      },
      { actionScheduledAt }
    );
  }
}
