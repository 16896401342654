import React from 'react';
import RCNotification from 'rc-notification';
import Alert from '@mui/material/Alert';

const defaultDuration = 6;
let notification;
RCNotification.newInstance({}, (n) => (notification = n));

const showMsg = ({ content, duration = defaultDuration, type, key }) => {
  const handleClose = () => notification.removeNotice(key);

  notification.notice({
    key,
    duration,
    content: (
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {content}
      </Alert>
    ),
  });
};

const error = ({
  content = 'Oops! Something unexpected happened.',
  key = `default-error-key`,
}) => showMsg({ content, key, type: 'error' });
const success = ({ content = 'Success', key = 'default-success-key' }) =>
  showMsg({ content, key, type: 'success' });
const warning = ({ content = 'Warning', key = 'default-warning-key' }) =>
  showMsg({ content, key, type: 'warning' });

export const ToastService = {
  error,
  warning,
  success,
};
