import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useProjectApi from '../Utils/Api/useProjectApi';

export default function useProjects() {
  const { orgId } = useParams();
  const api = useProjectApi();

  return useQuery(['projects', orgId], async () => {
    const data = await api.getProjects(orgId);
    return data?.projects || [];
  });
}
