import { createTheme, css } from '@mui/material/styles';
import GalanoGrotesqueNormal from '../assets/fonts/galano-grotesque.otf';
import GalanoGrotesqueMedium from '../assets/fonts/galano-grotesque-medium.otf';
import GalanoGrotesqueBold from '../assets/fonts/galano-grotesque-bold.otf';
import GalanoGrotesqueSemiBold from '../assets/fonts/galano-grotesque-semi-bold.otf';

const animationSpeed = '0.3s';
const animationFunction = 'ease-out';

const RCStyles = css`
  .rc-notification {
    position: fixed;
    z-index: 1260;
    transform: translateX(-50%);
  }
  .rc-notification-notice {
    margin: 10px 0;
  }
  .rc-notification-fade-appear,
  .rc-notification-fade-enter {
    opacity: 0;
    animation-duration: 0.15s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  .rc-notification-fade-leave {
    animation-duration: 0.15s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
    animation-play-state: paused;
  }
  .rc-notification-fade-appear.rc-notification-fade-appear-active,
  .rc-notification-fade-enter.rc-notification-fade-enter-active {
    animation-name: rcNotificationFadeIn;
    animation-play-state: running;
  }
  .rc-notification-fade-leave.rc-notification-fade-leave-active {
    animation-name: rcDialogFadeOut;
    animation-play-state: running;
  }
  @keyframes rcNotificationFadeIn {
    0% {
      opacity: 0;
      transform: translateY(-50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes rcDialogFadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-50%);
    }
  }
`;

const reactModal = css`
  @keyframes modal-video {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes modal-video-inner {
    from {
      transform: translate(0, 100px);
    }

    to {
      transform: translate(0, 0);
    }
  }

  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ${animationSpeed};
    animation-duration: ${animationSpeed};
    animation-name: modal-video;
    -webkit-transition: opacity ${animationSpeed} ${animationFunction};
    -moz-transition: opacity ${animationSpeed} ${animationFunction};
    -ms-transition: opacity ${animationSpeed} ${animationFunction};
    -o-transition: opacity ${animationSpeed} ${animationFunction};
    transition: opacity ${animationSpeed} ${animationFunction};
  }

  .modal-video-effect-exit {
    opacity: 0;

    & .modal-video-movie-wrap {
      -webkit-transform: translate(0, 100px);
      -moz-transform: translate(0, 100px);
      -ms-transform: translate(0, 100px);
      -o-transform: translate(0, 100px);
      transform: translate(0, 100px);
    }
  }

  .modal-video-body {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (orientation: landscape) {
      padding: 10px 60px;
      box-sizing: border-box;
    }
  }

  .modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ${animationFunction};
    animation-duration: ${animationSpeed};
    animation-name: modal-video-inner;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform ${animationSpeed} ${animationFunction};
    -moz-transition: -moz-transform ${animationSpeed} ${animationFunction};
    -ms-transition: -ms-transform ${animationSpeed} ${animationFunction};
    -o-transition: -o-transform ${animationSpeed} ${animationFunction};
    transition: transform ${animationSpeed} ${animationFunction};

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -45px;
    right: 0px;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;

    @media (orientation: landscape) {
      top: 0;
      right: -45px;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      border-radius: 5px;
      margin-top: -6px;
    }
  }
`;

const main = css`
  html,
  body {
    margin: 0;
    padding: 0;
  }
  html {
    height: 100vh;
  }
  body {
    box-sizing: border-box;
    min-height: 100%;
    margin: 0;
    font-family: 'GalanoGrotesque', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body * {
    box-sizing: border-box;
  }

  #root {
    width: 100%;
    min-height: 100vh;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const fonts = css`
  @font-face {
    font-family: 'GalanoGrotesque';
    src: url(${GalanoGrotesqueNormal});
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GalanoGrotesque';
    src: url(${GalanoGrotesqueMedium});
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GalanoGrotesque';
    src: url(${GalanoGrotesqueSemiBold});
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'GalanoGrotesque';
    src: url(${GalanoGrotesqueBold});
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
`;

let theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        ${main.styles}
        ${fonts.styles}
        ${reactModal.styles}
        
        .custom_datepicker_popper {
          z-index: 1250 !important;
        }

        ${RCStyles.styles}
      `,
    },
  },
  typography: {
    fontFamily: ['"GalanoGrotesque"', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: 48,
      lineHeight: '68px',
      letterSpacing: '-0.2px',
    },
    h2: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: '46px',
    },
    h3: {
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '36px',
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '28px',
    },
    h5: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '28px',
    },
    title: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: '24px',
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
    },
    subtitle: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
    },
    captionTitle: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '18px',
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '18px',
    },
    overline: {
      fontWeight: 600,
      fontSize: 10,
      lineHeight: '16px',
    },
  },
  palette: {
    // black and white are default common.black/common.white
    primary: {
      main: '#005866',
      light: 'rgba(0,88,102,0.8)',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.08)',
      dark: 'rgba(0, 0, 0, 0.12)',
      light: 'rgba(0, 0, 0, 0.08)',
    },
    ternary: {
      main: '#333333',
      dark: 'rgba(51, 51, 51, 0.8)',
      light: '#999999',
    },
    success: {
      main: '#005866',
      light: 'rgba(0,88,102,0.8)',
    },
    error: {
      main: '#F24518',
      light: 'rgba(242, 69, 24, 0.8)',
    },
    info: {
      main: '#337CCD',
      dark: '#0A66C2',
      light: 'rgba(51, 124, 205, 0.8)',
    },
    warning: {
      main: '#ECC300',
    },
    grey: {
      100: 'rgba(0,88,102,0.04)',
      300: '#CCCCCC',
      500: '#999999',
      700: '#666666',
      900: '#333333',
    },
    white: {
      main: '#fff',
      light: 'rgba(0, 0, 0, 0.08)',
      dark: 'rgba(0, 0, 0, 0.16)',
    },
    transparent: {
      main: 'rgba(0, 0, 0, 0.16)',
      light: 'rgba(0, 0, 0, 0.08)',
      dark: 'rgba(0, 0, 0, 0.32)',
    },
  },
  shadows: {
    0: 'none',
    1: '0px 4px 8px rgba(0, 0, 0, 0.04)',
    2: '0 1px 1px 0 rgba(0, 0, 0, 0.08)',
    3: '0 2px 8px 0 rgba(0, 0, 0, 0.04)',
    4: '0px -1px 0px rgba(0, 0, 0, 0.08)',
    5: '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08)',
    8: '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08)',
    16: '0px 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08)',
  },
  padding: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '64px',
  },
  margin: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '64px',
  },
});

const commonButtonOverrides = {
  defaultProps: {
    disableRipple: true,
    disableElevation: true,
    variant: 'contained',
    color: 'success',
  },
  styleOverrides: {
    loadingIndicator: ({ ownerState = {} }) => ({
      color:
        ownerState.color === 'secondary'
          ? theme.palette.common.black
          : theme.palette.common.white,
    }),
    root: ({ ownerState = {} }) => ({
      textTransform: 'none',
      borderRadius: 4,
      minWidth: 'auto',
      border: 'none',
      ...(ownerState.size === 'large' && {
        padding: ownerState.children ? `12px ${theme.padding.lg}` : '12px',
        ...theme.typography.title,
      }),
      ...(ownerState.size === 'medium' && {
        padding: ownerState.children
          ? `${theme.padding.sm} ${theme.padding.md}`
          : '10px',
        ...theme.typography.subtitle,
      }),
      ...(ownerState.size === 'small' && {
        padding: ownerState.children ? '3px 6px' : theme.padding.xs,
        ...theme.typography.captionTitle,
      }),
      ...(ownerState.disabled && {
        opacity: '0.48',
      }),
    }),
    startIcon: ({ ownerState = {} }) => ({
      ...(!ownerState.children && {
        margin: 0,
      }),
      '&> *:nth-of-type(1)': {
        ...(ownerState.size === 'large' && {
          fontSize: 24,
        }),
        ...(ownerState.size === 'medium' && {
          fontSize: 16,
        }),
        ...(ownerState.size === 'small' && {
          fontSize: 16,
        }),
      },
    }),
  },
  variants: [
    {
      props: { color: 'success' },
      style: {
        backgroundColor: theme.palette.success.main,
        color: theme.palette.common.white,
        '&:disabled': {
          backgroundColor: theme.palette.success.light,
          color: theme.palette.common.white,
        },
        '&:hover': {
          backgroundColor: theme.palette.success.light,
          borderColor: 'transparent',
        },
      },
    },
    {
      props: { color: 'secondary' },
      style: {
        color: theme.palette.common.black,
        '&:disabled': {
          color: theme.palette.common.black,
        },
      },
    },
    {
      props: { color: 'white' },
      style: {
        border: `1px solid ${theme.palette.transparent.main}`,
        padding: '7px 15px', // + border = size
        justifyContent: 'space-between',
        '&:hover': {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    {
      props: { color: 'white', size: 'large' },
      style: {
        justifyContent: 'space-between',
        ...theme.typography.body2,
        padding: '12px 11px',
      },
    },
    {
      props: { color: 'error' },
      style: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        '&:disabled': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
        },
        '&:hover': {
          backgroundColor: theme.palette.error.light,
          borderColor: 'transparent',
        },
      },
    },
    {
      props: { color: 'transparent' },
      style: {
        backgroundColor: 'transparent',
        color: '#005866',
        padding: 0,
        '&:disabled': {
          opacity: 0.5,
        },
        '&:hover': {
          opacity: 0.8,
          backgroundColor: 'transparent',
        },
      },
    },
    {
      props: { loading: true },
      style: {
        color: 'transparent !important',
      },
    },
  ],
};

const inputRootStyled = ({ ownerState = {} }) => ({
  color: theme.palette.grey['900'],
  ...(ownerState.size === 'medium' && {
    padding:
      ownerState.hasOwnProperty('placeholder') && !ownerState.placeholder
        ? `${theme.padding.lg} 11px ${theme.padding.xs} !important`
        : '14px 11px !important',
    ...theme.typography.body2,
  }),
  ...(ownerState.startAdornment && {
    paddingLeft: '0 !important',
  }),
  ...(ownerState.size === 'small' && {
    padding: `${theme.padding.sm} 11px`,
    ...theme.typography.body2,
  }),
});

theme = createTheme(theme, {
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: theme.shadows[5],
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: theme.palette.common.white,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey['300'],
          border: 'none',
          color: theme.palette.grey['900'],
          height: 20,
          borderRadius: 10,
          margin: `0 ${theme.margin.sm} 0 0 !important`,
          '&:first-child': {
            marginLeft: '11px !important',
          },
        },
        label: {
          ...theme.typography.caption,
          padding: `1px 5px 1px ${theme.padding.sm}`,
        },
        deleteIcon: {
          fontSize: 16,
          marginRight: 3,
          marginLeft: 0,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& svg': {
            fontSize: '16px',
            color: theme.palette.common.black,
            marginLeft: 11,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          display: 'none',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: ({ ownerState = {} }) => ({
          color: theme.palette.grey['500'],
          transform:
            ownerState.formControl.filled || ownerState.formControl.adornedStart
              ? 'translate(12px, 7px) scale(0.75)'
              : 'translate(11px, 13px) scale(1)',
          '&.Mui-focused': {
            transform: 'translate(12px, 7px) scale(0.75)',
            color: theme.palette.grey['500'],
            fontWeight: '600',
          },
        }),
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'medium',
      },
      styleOverrides: {
        notchedOutline: {
          borderWidth: '1px !important',
          borderColor: theme.palette.transparent.light,
          top: 0,
          '> legend': {
            display: 'none',
          },
        },
        root: ({ ownerState = {} }) => ({
          ...inputRootStyled({ ownerState }),
          '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.error.main,
            },
          },
        }),
        input: ({ ownerState = {} }) => ({
          padding: '0 !important',
          '&.MuiSelect-select': {
            margin:
              ownerState.size === 'medium'
                ? '-14px -10px -22px'
                : '-8px -10px -16px',
            padding:
              ownerState.size === 'medium'
                ? '24px 32px 12px 10px !important'
                : '8px 32px 16px 10px !important',
            '~ .MuiOutlinedInput-notchedOutline': {
              ...(ownerState.size === 'small' && {
                '> legend': {
                  display: 'none',
                },
              }),
              ...(ownerState.size === 'medium' && {
                '> legend': {
                  visibility: 'visible',
                  backgroundColor: 'transparent',
                  display: 'contents',
                  '> span': {
                    paddingLeft: 2,
                    paddingTop: 5,
                  },
                },
              }),
            },
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'inherit !important',
            '&:hover': {
              backgroundColor: `${theme.palette.transparent.light} !important`,
            },
          },
        },
      },
    },
    MuiLoadingButton: commonButtonOverrides,
    MuiButton: commonButtonOverrides,
    MuiCheckbox: {
      styleOverrides: {
        root: {
          paddingRight: theme.padding.md,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: theme.padding.sm,
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        row: {
          '& .MuiFormControlLabel-root:not(:last-child)': {
            marginRight: theme.margin.md,
          },
        },
        root: {
          '& .MuiFormControlLabel-root:last-child': {
            marginRight: 0,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: theme.padding.md,
          borderRadius: 8,
        },
        message: {
          ...theme.typography.body2,
          color: theme.palette.grey['900'],
          padding: 0,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          ...theme.typography.title,
          marginBottom: 0,
          color: theme.palette.common.black,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: theme.margin.sm,
        },
      },
    },
  },
});

export default theme;
