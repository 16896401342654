import Api from './Api';

export default class MessageApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  async getConversations(projectId) {
    const { summary } = await this.doGet({
      path: `/projects/${projectId}/conversation-summary`,
    });
    return Object.entries(summary).map(
      ([
        id,
        {
          candidate: { id: candidateId, ...candidate },
          metadata,
          recipient,
          project,
        },
      ]) => ({
        id,
        project,
        candidateId,
        ...candidate,
        ...metadata,
        lastActivityAt: metadata.lastActivityAt
          ? new Date(metadata.lastActivityAt)
          : null,
        recipient,
      })
    );
  }

  async getMessages(conversationId) {
    const data = await this.doGet({
      path: `/conversations/${conversationId}`,
    });
    return {
      messages:
        data?.messages.map((message) => ({
          ...message,
          sender: { id: message.sender, ...data?.participants[message.sender] },
          createdAt: new Date(message.createdAt),
          deliveredAt: message.deliveredAt
            ? new Date(message.deliveredAt)
            : null,
        })) || [],
      participants: data?.participants
        ? Object.entries(data.participants).map(([id, participant]) => ({
            id,
            ...participant,
          }))
        : [],
    };
  }

  sendMessage(conversationId, message) {
    return this.doPost(
      {
        path: `/conversations/${conversationId}/send-message`,
      },
      { text: message }
    );
  }

  fetchNewestMessages(conversationId) {
    return this.doPost({
      path: `/conversations/${conversationId}/fetch-new`,
    });
  }

  fetchMessagesForAllConversations(projectId) {
    return this.doPost({
      path: `/projects/${projectId}/conversation/fetch-new`,
    });
  }
}
