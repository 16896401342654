import startCase from 'lodash/startCase';
import upperFirst from 'lodash/upperFirst';
import set from 'lodash/set';
import labels from '../labels';
import { ToastService } from '../ToastService';
import history from '../../history';
import { LAST_ORG_ID_KEY } from 'Utils/Storage';

export class ApiError extends Error {
  constructor(response) {
    super(`Failed to fetch (${response.status}): ${response.url}`);
    this.response = response;
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

function errorHandler(response) {
  const { data, headers = {}, status, url } = response;

  if (data?.error === 'ACCESS_DENIED') {
    localStorage.removeItem(LAST_ORG_ID_KEY);
    history.replace('/');
  } else if (headers['retry-after']) {
    const retryIn = Math.ceil(parseInt(headers['retry-after'], 10) / 60);
    ToastService.error({
      content: `Email verification already resent. Please retry in ${retryIn} minutes`,
    });
  } else if (status !== 401 || url.includes('/auth')) {
    ToastService.error({
      content: data.error
        ? labels[data.error] ||
          upperFirst(startCase(data.message || data.error).toLowerCase())
        : undefined,
    });
  }
}

async function parseBody(type, res) {
  try {
    if (type === 'json') {
      const body = await res.json();
      return body;
    }
    const body = await res.blob();
    if (type === 'file') {
      const fileName = res.headers
        .get('content-disposition')
        .split('filename=')
        .at(-1);
      return new File([body], fileName);
    }
    return body;
  } catch {
    return null;
  }
}

export default class Api {
  constructor(config) {
    this.config = config;
  }

  static parseValidationErrors({ error, details }) {
    if (error === 'VALIDATION_FAILED' && Array.isArray(details?.violations)) {
      return details.violations.reduce((memo, next) => {
        set(memo, next.propertyPath, next.title);

        return memo;
      }, {});
    }
    return {};
  }

  getHeaders() {
    return typeof this.config.headers === 'function'
      ? this.config.headers()
      : this.config.headers ?? {};
  }

  async execute({
    path,
    data,
    type = 'json',
    method = 'GET',
    params = {},
    headers = {},
    onError = errorHandler,
  }) {
    let url = `/api${path}`;
    const entries = Object.entries(params);

    if (entries.length) {
      const searchParams = new URLSearchParams();
      entries
        .filter(([_, value]) => !!value)
        .forEach(([name, value]) => {
          searchParams.append(name, `${value}`);
        });
      url = `${url}?${searchParams.toString()}`;
    }

    try {
      const request = {
        method,
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          'Content-Type': 'application/json',
          ...this.getHeaders(),
          ...headers,
        },
      };
      if (data && method !== 'GET') {
        request.body = JSON.stringify(data);
      }
      const response = await fetch(url, request);

      // Don't try to parse on no-content
      if (response.status === 204) {
        return null;
      }

      const body = await parseBody(type, response);

      if (response.ok) {
        return body;
      } else {
        throw new ApiError({
          data: body,
          headers: response.headers,
          status: response.status,
          url: response.url,
        });
      }
    } catch (error) {
      if (onError && error.response) {
        onError(error.response);
      }
      throw error;
    }
  }

  async doGet(options) {
    return this.execute({
      ...options,
      method: 'GET',
    });
  }

  async doPost(options, data = {}) {
    return this.execute({
      method: 'POST',
      data,
      ...options,
    });
  }

  async doPut(options, data = {}) {
    return this.execute({
      ...options,
      data,
      method: 'PUT',
    });
  }

  async doDelete(options, data = {}) {
    return this.execute({
      ...options,
      data,
      method: 'DELETE',
    });
  }
}
