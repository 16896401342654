import { createContext, useContext } from 'react';

export const APP_EVENTS = {
  BRIEF_COMPLETE: 'BRIEF_COMPLETE',
  NEW_PROJECT: 'NEW_PROJECT',
  OPEN_DRAWER: 'OPEN_DRAWER',
  OPEN_TUTORIAL: 'OPEN_TUTORIAL',
  OPEN_LI_INSTRUCTIONS: 'OPEN_LI_INSTRUCTIONS',
};

function createEventBusHook() {
  const listeners = new Map();

  const getListeners = (eventName) => {
    const genericEventName = eventName;
    if (!listeners.has(genericEventName)) {
      listeners.set(genericEventName, []);
    }
    return listeners.get(genericEventName);
  };

  const subscribe = (eventName, listener) => {
    getListeners(eventName).push(listener);

    // unsubscribe function
    return () => {
      const index = getListeners(eventName).indexOf(listener);
      if (index !== -1) {
        getListeners(eventName).splice(index, 1);
      }
    };
  };

  const dispatch = (eventName, payload) => {
    getListeners(eventName).forEach((listener) => listener(payload));
  };

  const Context = createContext({
    subscribe,
    dispatch,
  });

  return () => useContext(Context);
}

export const useAppEvents = createEventBusHook();
