import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Modal from 'Components/Modal';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {
  Heading,
  Grid,
  StepListItem,
  NumberWrapper,
  FAQContainer,
  FAQTitle,
  FAQDescription,
} from './styled';

const LinkedInInstructionsModal = ({ open, onClose }) => {
  const theme = useTheme();

  return (
    <Modal
      open={open}
      title="Connect LinkedIn"
      subTitle="Once you connect a LinkedIn account to jobRely, you can start outreach campaigns."
      onClose={onClose}
    >
      <Heading variant="title">How to connect?</Heading>
      <Grid>
        <NumberWrapper variant="subtitle">1</NumberWrapper>
        <StepListItem>
          <a
            href="https://chrome.google.com/webstore/detail/jobrely-talent-sourcer/ilefbajpbpafapbnjphkjneejpepdipc"
            target="_blank"
            rel="noreferrer"
          >
            Download
          </a>{' '}
          jobRely Talent Sourcer extension (works with Chrome).
        </StepListItem>
        <NumberWrapper variant="subtitle">2</NumberWrapper>
        <StepListItem>
          Open any LinkedIn page and jobRely Talent Sourcer will launch
          automatically.
        </StepListItem>
        <NumberWrapper variant="subtitle">3</NumberWrapper>
        <StepListItem sx={{ display: 'inline-flex', alignItems: 'center' }}>
          Click on&nbsp;
          <LinkedInIcon
            sx={{
              color: theme.palette.info.dark,
            }}
          />
          &nbsp;to connect LinkedIn to jobRely
        </StepListItem>
      </Grid>
      <Box>
        <FAQContainer>
          <FAQTitle expandIcon={<ArrowDropDownIcon />}>
            Why do I need to connect my LinkedIn account to jobRely?
          </FAQTitle>
          <FAQDescription>
            It enables outreach on jobRely projects. It’s totally safe and only
            members of your organization will be able to use it for their
            campaigns. The usage is strictly limited to communication with
            contacts. It will never be used outside the scope of your jobRely
            projects.
          </FAQDescription>
        </FAQContainer>
        <FAQContainer>
          <FAQTitle expandIcon={<ArrowDropDownIcon />}>
            How does jobRely secure my LinkedIn credentials?
          </FAQTitle>
          <FAQDescription>
            Contrary to other LinkedIn automation tools, jobRely doesn’t require
            your LinkedIn credentials to connect your account. Once logged in to
            your LinkedIn, press “Connect” on jobRely Chrome extension and you
            are all set.
          </FAQDescription>
        </FAQContainer>
        <FAQContainer>
          <FAQTitle expandIcon={<ArrowDropDownIcon />}>
            Can I use jobRely without connecting LinkedIn account?
          </FAQTitle>
          <FAQDescription>
            Yes you can, but you will not be able to reach out to any contacts.
            Your activities on jobRely platform will be limited to sourcing,
            reviewing contacts and setting up campaigns.
          </FAQDescription>
        </FAQContainer>
      </Box>
    </Modal>
  );
};

LinkedInInstructionsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default LinkedInInstructionsModal;
