import Api from './Api';

export default class ProjectApi extends Api {
  constructor(config = {}) {
    super(config);
  }

  getProject(projectId) {
    return this.doGet({ path: `/projects/${projectId}` });
  }

  searchProjects(query) {
    return this.doGet({
      path: `/projects/search?${
        query ? `q=${query}&` : ''
      }filters[status][]=ACTIVE&filters[status][]=ON_HOLD`,
    });
  }

  getProjectOverview(projectId) {
    return this.doGet({ path: `/projects/${projectId}/overview` });
  }

  getCampaignSteps(projectId) {
    return this.doGet({ path: `/projects/${projectId}/campaign/steps` });
  }

  createCampaignStep(projectId, data) {
    return this.doPost({ path: `/projects/${projectId}/campaign/steps` }, data);
  }

  removeCampaignStep(projectId, stepId) {
    return this.doDelete({
      path: `/projects/${projectId}/campaign/steps/${stepId}`,
    });
  }

  updateCampaignStep(projectId, stepId, data) {
    return this.doPost(
      {
        path: `/projects/${projectId}/campaign/steps/${stepId}`,
      },
      data
    );
  }

  getCampaignCandidates(projectId) {
    return this.doGet({ path: `/projects/${projectId}/campaign-candidates` });
  }

  getProjects(orgId) {
    return this.doGet({ path: `/organisations/${orgId}/projects` });
  }

  getProjectCandidatesSummary(projectId) {
    return this.doGet({
      path: `/projects/${projectId}/project-candidates/summary`,
    });
  }

  getProjectCandidates({
    projectId,
    reviewStatus,
    searchName,
    page = 0,
    limit = 50,
  }) {
    return this.doGet({
      path: `/projects/${projectId}/project-candidates?page=${page}&limit=${limit}&reviewStatus=${reviewStatus}${
        searchName ? `&searchName=${searchName}` : ''
      }`,
    });
  }

  approveProjectCandidates(projectId, candidateIds) {
    return this.doPost(
      {
        path: `/projects/${projectId}/candidates/mark-as/approved`,
      },
      {
        candidateIds,
      }
    );
  }

  rejectProjectCandidates(projectId, candidateIds, reason) {
    return this.doPost(
      {
        path: `/projects/${projectId}/candidates/mark-as/rejected`,
      },
      {
        candidateIds,
        reason,
      }
    );
  }

  markProjectCandidatesAsMaybeLater(projectId, candidateIds) {
    return this.doPost(
      {
        path: `/projects/${projectId}/candidates/mark-as/maybe-later`,
      },
      {
        candidateIds,
      }
    );
  }

  copyProjectCandidates(candidateIds, targetProjectIds) {
    return this.doPost(
      {
        path: '/projects/copy-candidates',
      },
      {
        candidates: candidateIds,
        toProjects: targetProjectIds,
      }
    );
  }

  getCampaign(projectId) {
    return this.doGet({
      path: `/projects/${projectId}/campaign`,
    });
  }

  getFirstSuggestion(projectId, stepId) {
    return this.doGet({
      path: `/projects/${projectId}/campaign/steps/${stepId}/suggestions`,
      onError: () => {},
    });
  }

  getNextSuggestion(projectId, stepId, index, message) {
    return this.doPost(
      {
        path: `/projects/${projectId}/campaign/steps/${stepId}/suggestions/${index}`,
        onError: () => {},
      },
      { message }
    );
  }

  updateCampaign(projectId, campaign) {
    return this.doPost(
      {
        path: `/projects/${projectId}/campaign`,
      },
      {
        ...campaign,
      }
    );
  }

  pauseCampaign(projectId) {
    return this.doPost({
      path: `/projects/${projectId}/campaign/pause`,
    });
  }

  startCampaign(projectId) {
    return this.doPost({
      path: `/projects/${projectId}/campaign/active`,
    });
  }

  deleteProjectCandidates(projectId, candidateIds) {
    return this.doDelete(
      {
        path: `/projects/${projectId}/delete-candidates`,
      },
      {
        candidateIds,
      }
    );
  }

  changeProjectStatus(projectId, status) {
    return this.doPost({
      path: `/projects/${projectId}/change-status?status=${status}`,
    });
  }

  deleteProject(projectId) {
    return this.doDelete({
      path: `/projects/${projectId}`,
    });
  }

  updateProject(project) {
    return this.doPost(
      {
        path: `/projects/${project.id}`,
      },
      {
        ...project,
      }
    );
  }

  getProjectBrief(projectId) {
    return this.doGet({
      path: `/projects/${projectId}/brief`,
    });
  }

  getProjectIMR(projectId) {
    return this.doGet({
      path: `/projects/${projectId}/imr`,
    });
  }

  updateProjectBrief(projectId, projectBrief) {
    return this.doPost(
      {
        path: `/projects/${projectId}/brief`,
      },
      {
        ...projectBrief,
      }
    );
  }

  submitProjectBrief(projectId, projectBrief) {
    return this.doPost(
      {
        path: `/projects/${projectId}/brief/submit`,
      },
      {
        ...projectBrief,
      }
    );
  }

  updateProjectIMR(projectId, imr) {
    return this.doPost(
      {
        path: `/projects/${projectId}/imr`,
      },
      {
        ...imr,
      }
    );
  }

  getBriefSetting(orgId) {
    // TODO: connect BE
    return Promise.resolve({
      includedCompanies: [],
      excludedCompanies: [],
    });
  }

  exportCandidatesToCSV(candidateIds) {
    return this.doPost(
      {
        type: 'file',
        path: '/talent-pool/candidates/csv-export',
      },
      {
        candidateIds,
      }
    );
  }

  pauseCampaignCandidates(projectId, candidateIds) {
    return this.doPost(
      {
        path: `/projects/${projectId}/campaign/candidates/pause`,
      },
      {
        candidateIds,
      }
    );
  }

  resumeCampaignCandidates(projectId, candidateIds) {
    return this.doPost(
      {
        path: `/projects/${projectId}/campaign/candidates/resume`,
      },
      {
        candidateIds,
      }
    );
  }

  getIMRNotificationState(projectId) {
    return this.doGet({
      path: `/projects/${projectId}/imr/notification-email/state`,
    });
  }

  sendIMRNotification(projectId, message, recipients) {
    return this.doPost(
      {
        path: `/projects/${projectId}/imr/notification-email/send`,
      },
      {
        message,
        recipients,
      }
    );
  }

  getNotificationState(projectId) {
    return this.doGet({
      path: `/projects/${projectId}/project-candidates/ready-for-review-notification-email/state`,
    });
  }

  sendNotification(projectId, notification) {
    return this.doPost(
      {
        path: `/projects/${projectId}/project-candidates/ready-for-review-notification-email/send`,
      },
      notification
    );
  }
}
