import { Route, Routes } from 'react-router-dom';
import lazyLoad from '../../../../Utils/lazyLoad';

const View = lazyLoad(() => import('../View'));
const Join = lazyLoad(() => import('../Join'));
const Create = lazyLoad(() => import('../Create'));
const Invite = lazyLoad(() => import('../Invite'));
const Remove = lazyLoad(() => import('../Remove'));
const Update = lazyLoad(() => import('../Update'));

export default ({ root }) => {
  return root ? (
    <View />
  ) : (
    <Routes>
      <Route element={<View />}>
        <Route path="join" element={<Join />} />
        <Route path="create" element={<Create />} />
        <Route path="invite" element={<Invite />} />
        <Route path="remove" element={<Remove />} />
        <Route path="update" element={<Update />} />
      </Route>
    </Routes>
  );
};
