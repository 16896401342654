import { lazy, Suspense } from 'react';

export default function lazyLoad(loadable) {
  const AsyncComponent = lazy(loadable);

  return (props) => (
    <Suspense fallback={null}>
      <AsyncComponent {...props} />
    </Suspense>
  );
}
